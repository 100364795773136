::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  scroll-behavior: smooth;
}

.sticky-container {
  position: sticky;
  top: 0;
  /* background-color: transparent; */

  z-index: 999;
  /* Optional: If you want the sticky element to appear above other content */
  /* Add any other styles you want for the sticky container here */
}

.image-zoom-container {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: zoom-in;
  transition: transform 0.5s;
}

.image-zoom-container:hover .image-zoom-wrapper {
  transform: scale(1.8);
  /* You can adjust the scale factor to control the zoom level */
}

.image-zoom-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.5s;
}



.pagination-container {
  display: flex;
  justify-content: end;
  padding: 0rem 0;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin-right: 10px;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination a {
  padding: 6px 12px;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  text-decoration: none;
  color: #474747;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #f4f4f4;
}

.pagination .active a {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  cursor: default;
}

.pagination .disabled a {
  color: #aaa;
  cursor: not-allowed;
}

/* since nested groupes are not supported we have to use 
     regular css for the nested dropdowns 
  */
li>ul {
  transform: translatex(100%) scale(0);
}

li:hover>ul {
  transform: translatex(101%) scale(1);
}

li>button svg {
  transform: rotate(-90deg);
}

li:hover>button svg {
  transform: rotate(-270deg);
}

/* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}

.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}

.scale-0 {
  transform: scale(0);
}

.min-w-32 {
  min-width: 8rem;
}